import React from 'react';
import { useSession, signIn } from 'next-auth/react';
import Link from 'next/link';
import styles from './SignInButton.module.scss';

export default function SignInButton({
  signInLabel,
}: {
  signInLabel: string;
}): JSX.Element {
  const { data: session } = useSession();

  if (session) {
    return (
      <Link className={styles.link} href={'/account'}>
        {session.user?.name}
      </Link>
    );
  }

  return (
    <button className={styles.button} onClick={() => signIn()}>
      {signInLabel}
    </button>
  );
}
