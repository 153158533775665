import React, { useEffect, useRef, useState } from 'react';
import TopLoadingBar from '@resources/js/components/TopLoadingBar/TopLoadingBar';
import { useRouter } from 'next/router';
import { useNProgress } from '@tanem/react-nprogress';

export default function RouteChangeIndicator(): JSX.Element {
  const [isAnimating, setIsAnimating] = useState(false);

  const { isFinished, progress } = useNProgress({
    isAnimating,
  });

  const { events } = useRouter();
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      timeoutIdRef.current = setTimeout(() => {
        setIsAnimating(true);
      }, 300);
    };

    const handleRouteChangeEnd = () => {
      setIsAnimating(false);

      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };

    events.on('routeChangeStart', handleRouteChangeStart);
    events.on('routeChangeComplete', handleRouteChangeEnd);
    events.on('routeChangeError', handleRouteChangeEnd);

    return () => {
      setIsAnimating(false);

      events.off('routeChangeStart', handleRouteChangeStart);
      events.off('routeChangeComplete', handleRouteChangeEnd);
      events.off('routeChangeError', handleRouteChangeEnd);

      if (timeoutIdRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [events]);

  return (
    <TopLoadingBar
      isFinished={isFinished}
      progress={progress}
      animationDuration={100}
      color={'#8b3ff5'}
    />
  );
}
