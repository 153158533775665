import { AdPlacementFallback } from '@resources/js/components/AdPlacementFallback/AdPlacementFallback';
import PlaywireAd from '../PlaywireAd/PlaywireAd';
import React from 'react';
import convertImageUrlToOptimizedImageUrl from '../../helpers/convertImageUrlToOptimizedImageUrl';
import styles from './ArchonAdPlacement.module.scss';
import cx from 'classnames';
import { AdPlacementLocation } from '@resources/js/typings/viewModels/adPlacements';
import useAdPlacements from '@resources/js/contexts/useAdPlacements';

export type Props = {
  location: AdPlacementLocation;
  className?: string;
};

export const ArchonAdPlacement = ({
  className = '',
  location,
}: Props): JSX.Element | null => {
  const adPlacements = useAdPlacements();

  const adPlacementViewModel = adPlacements[location];
  if (!adPlacementViewModel?.adPlacementPlaywireViewModel) {
    return null;
  }
  const { adPlacementPlaywireViewModel, adPlacementFallbackViewModel } =
    adPlacementViewModel;

  const component = (
    <PlaywireAd
      {...adPlacementPlaywireViewModel}
      fallback={
        adPlacementFallbackViewModel ? (
          <AdPlacementFallback
            {...adPlacementFallbackViewModel}
            backgroundImageUrl={convertImageUrlToOptimizedImageUrl(
              adPlacementFallbackViewModel.backgroundImageUrl,
              256,
              50
            )}
          />
        ) : null
      }
    />
  );

  return (
    <div
      className={cx([
        styles.adPlacement,
        styles[`type--${location}`],
        className,
      ])}
    >
      {component}
    </div>
  );
};
