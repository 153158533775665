declare global {
  interface Window {
    isStorybook: boolean;
  }
}

/**
 * Having to use this method should be a code smell, so only use it as a last resort.
 */
export default function isStorybook(): boolean {
  return typeof window !== 'undefined' && window.isStorybook;
}
