import { useState, useEffect } from 'react';
import { safeLocalStorage } from '../helpers/localStorage';

// From: https://usehooks.com/useLocalStorage/
function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T | ((previousValue: T) => T)) => void] {
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(initialValue);

  const setValue = (value: T | ((prevState: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      safeLocalStorage.set(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    const maybeValue = safeLocalStorage.get(key);

    if (maybeValue) {
      try {
        setStoredValue(JSON.parse(maybeValue));
      } catch {
        // do nothing
      }
    }
  }, [key]);

  return [storedValue, setValue];
}

export default useLocalStorage;
