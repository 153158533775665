import React from 'react';

// Taken from NProgress example: https://codesandbox.io/s/github/tanem/react-nprogress/tree/master/examples/hoc?file=/src/index.tsx:279-339
const TopLoadingBar = ({
  isFinished,
  progress,
  animationDuration,
  color,
}: {
  animationDuration: number;
  isFinished: boolean;
  progress: number;
  color: string;
}): JSX.Element => (
  <Container animationDuration={animationDuration} isFinished={isFinished}>
    <Bar
      animationDuration={animationDuration}
      progress={progress}
      color={color}
    />
  </Container>
);

const Bar = ({
  animationDuration,
  progress,
  color,
}: {
  animationDuration: number;
  progress: number;
  color: string;
}) => (
  <div
    style={{
      background: color,
      height: 2,
      left: 0,
      marginLeft: `${(-1 + progress) * 100}%`,
      position: 'fixed',
      top: 0,
      transition: `margin-left ${animationDuration}ms linear`,
      width: '100%',
      zIndex: 1031,
    }}
  >
    <div
      style={{
        boxShadow: `0 0 10px ${color}, 0 0 5px ${color}`,
        display: 'block',
        height: '100%',
        opacity: 1,
        position: 'absolute',
        right: 0,
        transform: 'rotate(3deg) translate(0px, -4px)',
        width: 100,
      }}
    />
  </div>
);

const Container = ({
  animationDuration,
  children,
  isFinished,
}: {
  animationDuration: number;
  children: React.ReactNode;
  isFinished: boolean;
}) => (
  <div
    style={{
      opacity: isFinished ? 0 : 1,
      pointerEvents: 'none',
      transition: `opacity ${animationDuration}ms linear`,
    }}
  >
    {children}
  </div>
);

export default TopLoadingBar;
