export const safeLocalStorage = (() => {
  const isSupported = (() => {
    if (typeof window === 'undefined') {
      return false;
    }

    try {
      const key = 'ls-test';
      localStorage.setItem(key, key);
      localStorage.getItem(key);
      localStorage.removeItem(key);

      return true;
    } catch {
      return false;
    }
  })();

  // despite feature availability is thoroughly checked above, overwolf still
  // manages to have localStorage === null at times
  return {
    set: (key: string, value: string): void => {
      if (!isSupported) {
        return;
      }

      try {
        localStorage.setItem(key, value);
      } catch {
        // ignore
      }
    },
    get: (key: string): string | null => {
      if (!isSupported) {
        return null;
      }

      try {
        return localStorage.getItem(key);
      } catch {
        return null;
      }
    },
    remove: (key: string): void => {
      if (!isSupported) {
        return;
      }

      try {
        localStorage.removeItem(key);
      } catch {
        // ignore
      }
    },
  };
})();
