declare global {
  interface Window {
    locale: string;
  }
}

function fromNow(
  date: Date,
  lowestUnit:
    | 'seconds'
    | 'minutes'
    | 'hours'
    | 'days'
    | 'months'
    | 'years' = 'seconds'
): string {
  const seconds = (new Date().getTime() - date.getTime()) / 1000;
  const years = seconds / 31536000;
  const months = seconds / 2592000;
  const days = seconds / 86400;

  const relativeTimeFormatter = new Intl.RelativeTimeFormat(
    globalThis.window?.locale ?? 'en'
  );

  if (days >= 320 || days <= -320 || lowestUnit === 'years') {
    return relativeTimeFormatter.format(-roundAwayFromZero(years), 'year');
  }
  if (
    (days >= 26 && days <= 319) ||
    (days <= -26 && days >= -319) ||
    lowestUnit === 'months'
  ) {
    return relativeTimeFormatter.format(-roundAwayFromZero(months), 'month');
  }

  const hours = seconds / 3600;

  if (
    (hours >= 22 && days <= 26 * 24) ||
    (hours <= -22 && days >= -26 * 24) ||
    lowestUnit === 'days'
  ) {
    return relativeTimeFormatter.format(-roundAwayFromZero(days), 'day');
  }

  const minutes = seconds / 60;

  if (
    (minutes >= 45 && minutes <= 22 * 60) ||
    (minutes <= -45 && minutes >= -22 * 60) ||
    lowestUnit === 'hours'
  ) {
    return relativeTimeFormatter.format(-roundAwayFromZero(hours), 'hour');
  }
  if (
    (seconds >= 45 && seconds <= 45 * 60) ||
    (seconds <= -45 && seconds >= -44 * 60) ||
    lowestUnit === 'minutes'
  ) {
    return relativeTimeFormatter.format(-roundAwayFromZero(minutes), 'minute');
  }

  return relativeTimeFormatter.format(-roundAwayFromZero(seconds), 'second');
}

// Prefer to show "1 second ago" instead of "0 seconds ago"
function roundAwayFromZero(value: number): number {
  return value > 0 && value < 1
    ? 1
    : value < 0 && value > -1
    ? -1
    : Math.round(value);
}

export default fromNow;
