import React from 'react';
import VerticalContent from '@resources/js/components/VerticalContent/VerticalContent';
import cx from 'classnames';
import styles from './Advertisements.module.scss';
import Link from 'next/link';
import useGameSlug from '../../hooks/useGameSlug';

export type AdvertisementsProps = {
  className?: string;
  direction?: 'horizontal' | 'vertical';
  rounded?: boolean;
  showChrome?: boolean;
  children?: React.ReactNode;
};

export default function Advertisements({
  className = '',
  direction = 'vertical',
  rounded = true,
  showChrome = true,
  children,
}: AdvertisementsProps): JSX.Element {
  const gameSlug = useGameSlug() ?? 'wow';

  return (
    <div
      className={cx({
        [styles.advertisements]: true,
        [styles.advertisementsChrome]: showChrome,
        [styles.advertisementsRounded]: rounded,
        [className || '']: Boolean(className),
      })}
    >
      <VerticalContent
        gap={'tiny'}
        className={cx({
          [styles.advertisementsRightAligned]:
            !showChrome && direction === 'horizontal',
        })}
      >
        <div
          className={cx({
            [styles.header]: true,
          })}
        >
          {showChrome ? (
            <>
              <div className={styles.advertisementsTitle}>Advertisements</div>
              <Link
                className={styles.removeAdsLink}
                href={`/${gameSlug}/articles/help/how-to-remove-ads-on-archon`}
                prefetch={false}
              >
                Remove Ads
              </Link>
            </>
          ) : null}
        </div>
        <div
          className={cx({
            [styles.spots]: true,
            [styles.spotsHorizontal]: direction === 'horizontal',
          })}
        >
          {children}
        </div>
      </VerticalContent>
    </div>
  );
}
