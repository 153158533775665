import React, { useEffect } from 'react';
import { AdPlacementFallbackViewModel } from '@resources/js/typings/viewModels/adPlacements';
import useAdBlockMetrics from '@resources/js/contexts/useAdBlockMetrics';

export type Props = AdPlacementFallbackViewModel & {
  className?: string;
};

export const AdPlacementFallback = ({
  adPlacementFallbackType,
  siteTitle,
  headerImageUrl,
  backgroundImageUrl,
  href,
}: Props): JSX.Element => {
  const { increment } = useAdBlockMetrics();
  const [firstRender, setFirstRender] = React.useState(true);

  useEffect(() => {
    if (!firstRender) return;
    setFirstRender(false);
    increment('fallback');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return (
    <div className={`a52596fdd-fallback`}>
      <a
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        className={`a52596fdd-fallback__layout a52596fdd-fallback__layout--${adPlacementFallbackType}`}
        style={{ backgroundImage: 'url(' + backgroundImageUrl + ')' }}
      >
        <img
          src={headerImageUrl}
          alt={siteTitle + ' Logo'}
          className={'a52596fdd-fallback__site-logo'}
        />
        <div className={'a52596fdd-fallback__content'}>
          <div>
            <span className={'a52596fdd-fallback__greeting'}>HEY THERE.</span>
            <div className={'a52596fdd-fallback__remain-free'}>
              We all want {siteTitle} to remain <b>free</b>.
            </div>
          </div>
          <div className={'a52596fdd-fallback__work-hard'}>
            We work hard to offer you the best possible experience.
          </div>
          <div>
            Help us by{' '}
            <span className={'a52596fdd-fallback__linkish'}>
              disabling your Adblock
            </span>{' '}
            or supporting us on{' '}
            <span className={'a52596fdd-fallback__linkish'}>Patreon</span>.
          </div>
          <div className={'a52596fdd-fallback__thank-you'}>
            Thank you for your help and support!
          </div>
          <div className={'a52596fdd-fallback__view-more'}>
            <span className={'a52596fdd-fallback__linkish'}>
              View All Subscriber Perks
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};
