import React, { useEffect, useState } from 'react';
import Button from '@resources/js/components/Button/Button';
import ScreenReaderOnly from '@resources/js/components/ScreenReaderOnly/ScreenReaderOnly';
import { useRouter } from 'next/router';
import styles from './ArchonAdPlacementWithCloseButton.module.scss';

export type Props = {
  children: React.ReactNode;
  className?: string;
  onHideStateChange?: (isHidden: boolean) => void;
};

function CloseButtonIcon(): JSX.Element {
  return (
    <>
      <svg
        width='12'
        height='12'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6 6L26 26'
          stroke='#DEDEDE'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M26 6L6 26'
          stroke='#DEDEDE'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </svg>
    </>
  );
}

export const ArchonAdPlacementWithCloseButton = ({
  children,
  onHideStateChange,
}: Props): JSX.Element | null => {
  const router = useRouter();
  const [hideAd, setHideAd] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);

  useEffect(() => {
    onHideStateChange && onHideStateChange(hideAd);
  }, [onHideStateChange, hideAd]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowCloseButton(true);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    setHideAd(false);
  }, [router.asPath]);

  if (hideAd) {
    return null;
  }

  return (
    <div>
      <div className={styles.controls}>
        {showCloseButton && (
          <Button styles={['unstyled']} onClick={() => setHideAd(true)}>
            <ScreenReaderOnly>Close Ad</ScreenReaderOnly>
            <CloseButtonIcon />
          </Button>
        )}
      </div>
      {children}
    </div>
  );
};
