import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ArchonLogo from '../ArchonLogo/ArchonLogo';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore tsc seems to bug out and misreport this line
import styles from './ArchonHeader.module.scss';
import Link from 'next/link';
import cx from 'classnames';
import ScreenReaderOnly from '@resources/js/components/ScreenReaderOnly/ScreenReaderOnly';
import Button from '@resources/js/components/Button/Button';
import Body from '@resources/js/components/Body/Body';
import { useRouter } from 'next/router';
import renderEnhancedMarkdownToString from '@resources/js/components/EnhancedMarkdown/helpers/renderEnhancedMarkdownToString';
import BreadcrumbSpecsMenu from '../BreadcrumbSpecsMenu/BreadcrumbSpecsMenu';
import SignInButton from '../SignInButton/SignInButton';

export type ArchonHeaderViewModel = {
  defaultBreadcrumbs: BreadcrumbViewModel[];
  signInLabel: string;
};

type ArchonHeaderProps = ArchonHeaderViewModel & {
  breadcrumbs?: BreadcrumbViewModel[];
};

export type BreadcrumbViewModel = {
  label: string;
  url?: string;
  options?: BreadcrumbViewModel[];
  menu?: BreadcrumbMenuViewModel;
};

type BreadcrumbMenuViewModel = {
  componentName: string;
  props: never;
};

export default function ArchonHeader({
  // eslint-disable-next-line react/prop-types
  defaultBreadcrumbs,
  signInLabel,
  breadcrumbs = [],
}: ArchonHeaderProps): JSX.Element {
  if (breadcrumbs.length === 0) {
    breadcrumbs = defaultBreadcrumbs;
  }

  const [hoveredBreadcrumb, setHoveredBreadcrumb] =
    useState<BreadcrumbViewModel | null>(null);

  const openMenuTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const closeMenuTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const openBreadcrumbMenu = useCallback((breadcrumb: BreadcrumbViewModel) => {
    if (openMenuTimeoutId.current) {
      clearTimeout(openMenuTimeoutId.current);
    }
    if (closeMenuTimeoutId.current) {
      clearTimeout(closeMenuTimeoutId.current);
    }

    openMenuTimeoutId.current = setTimeout(() => {
      setHoveredBreadcrumb(breadcrumb);
    }, 100);
  }, []);

  const closeBreadcrumbMenu = useCallback(() => {
    if (openMenuTimeoutId.current) {
      clearTimeout(openMenuTimeoutId.current);
    }
    if (closeMenuTimeoutId.current) {
      clearTimeout(closeMenuTimeoutId.current);
    }

    closeMenuTimeoutId.current = setTimeout(() => {
      setHoveredBreadcrumb(null);
    }, 100);
  }, []);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  useEffect(() => {
    const body = global.document?.body;
    if (body) {
      if (menuIsOpen) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = '';
      }
    }
  }, [menuIsOpen]);

  const { events } = useRouter();
  useEffect(() => {
    const handleRouteChangeEnd = () => {
      setMenuIsOpen(false);
    };

    events.on('routeChangeComplete', handleRouteChangeEnd);
    events.on('routeChangeError', handleRouteChangeEnd);

    return () => {
      events.off('routeChangeComplete', handleRouteChangeEnd);
      events.off('routeChangeError', handleRouteChangeEnd);
    };
  });

  return (
    <header
      className={cx({
        [styles.header]: true,
        [styles.headerWithBreadcrumbs]: breadcrumbs.length > 0,
      })}
    >
      <div className={styles.headerContent} onMouseLeave={closeBreadcrumbMenu}>
        <div className={styles.logoAndBreadcrumbs}>
          <Link href={'/'}>
            <div className={styles.logoContainer}>
              <ArchonLogo />
            </div>
          </Link>
          <nav aria-label={'Breadcrumb'} className={styles.breadcrumbs}>
            {breadcrumbs.map((breadcrumb, index) => {
              const isLast = index === breadcrumbs.length - 1;
              const Wrapper = breadcrumb.url
                ? ({ children }: { children: ReactNode }) => (
                    <Link href={breadcrumb.url ?? '/'}>{children}</Link>
                  )
                : Fragment;
              const breadcrumbHasMenu = hasMenu(breadcrumb);
              const menuComponentType = breadcrumb.menu
                ? menuComponentTypeFromString(breadcrumb.menu.componentName)
                : null;

              return (
                <Fragment key={index}>
                  <div className={styles.breadcrumb}>
                    {!isLast || hasMenu(breadcrumb) ? (
                      <Wrapper>
                        <span
                          className={cx({
                            [styles.lastBreadcrumb]: isLast,
                            [styles.hoveredBreadcrumb]:
                              breadcrumb === hoveredBreadcrumb &&
                              breadcrumbHasMenu,
                            [styles.rootBreadcrumb]: breadcrumb.url === '/',
                          })}
                          onMouseEnter={() => openBreadcrumbMenu(breadcrumb)}
                          onFocus={() => openBreadcrumbMenu(breadcrumb)}
                          aria-current={isLast ? 'page' : undefined}
                        >
                          {breadcrumb.label}
                          {breadcrumbHasMenu && <ChildBreadcrumbsIndicator />}
                        </span>
                      </Wrapper>
                    ) : (
                      <span
                        className={cx({
                          [styles.lastBreadcrumb]: isLast,
                        })}
                        onMouseEnter={closeBreadcrumbMenu}
                        aria-current={'page'}
                      >
                        {breadcrumb.label}
                      </span>
                    )}
                    <div
                      className={cx({
                        [styles.breadcrumbMenu]: true,
                        [styles.breadcrumbComponentMenu]: menuComponentType,
                      })}
                      style={{
                        display:
                          breadcrumb !== hoveredBreadcrumb || !breadcrumbHasMenu
                            ? 'none'
                            : undefined,
                      }}
                      onMouseLeave={closeBreadcrumbMenu}
                    >
                      {(breadcrumb.options ?? []).map((option, index) => (
                        <Link
                          href={option.url ?? '/'}
                          key={index}
                          className={styles.breadcrumbOption}
                        >
                          {option.label}
                        </Link>
                      ))}
                      {menuComponentType &&
                        React.createElement(
                          menuComponentType,
                          breadcrumb.menu?.props
                        )}
                    </div>
                  </div>
                  {!isLast && <Separator />}
                </Fragment>
              );
            })}
          </nav>
        </div>
        <SignInButton signInLabel={signInLabel} />
        {breadcrumbs.length > 0 && (
          <div className={styles.menuToggle}>
            <Button
              styles={['unstyled']}
              onClick={() => setMenuIsOpen((prev) => !prev)}
            >
              {menuIsOpen ? <CloseMenuIcon /> : <OpenMenuIcon />}
              <ScreenReaderOnly>
                {menuIsOpen ? 'Close Menu' : 'Open Menu'}
              </ScreenReaderOnly>
            </Button>
          </div>
        )}
      </div>
      {breadcrumbs.length > 0 && (
        <>
          <div
            className={styles.menuUnderlay}
            hidden={!menuIsOpen}
            onClick={() => setMenuIsOpen(false)}
          />
          <nav
            aria-label={'Breadcrumb'}
            className={styles.menu}
            hidden={!menuIsOpen}
          >
            <Body>
              <ul>
                {breadcrumbs.map((breadcrumb, index) => (
                  <li
                    key={index}
                    style={{
                      marginLeft: Math.max(index * 24 - 16, 0),
                    }}
                  >
                    {index > 0 && <DescendingArrow />}
                    {index === breadcrumbs.length - 1 ? (
                      <span
                        className={styles.lastBreadcrumb}
                        aria-current={'page'}
                      >
                        {breadcrumb.label}
                      </span>
                    ) : (
                      <Link href={breadcrumb.url ?? '/'}>
                        {breadcrumb.label}
                      </Link>
                    )}
                    {breadcrumb.options && (
                      <ul>
                        {breadcrumb.options.map((option, index) => (
                          <li key={index}>
                            <Link href={option.url ?? '/'}>{option.label}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </Body>
          </nav>
        </>
      )}
    </header>
  );
}

function hasMenu(breadcrumb: BreadcrumbViewModel): boolean {
  return Boolean(
    (breadcrumb.options && breadcrumb.options.length > 0) || breadcrumb.menu
  );
}

function menuComponentTypeFromString(
  componentName: string
): React.ComponentType<never> | null {
  switch (componentName) {
    case 'BreadcrumbSpecsMenu':
      return BreadcrumbSpecsMenu;
    default:
      return null;
  }
}

function Separator(): JSX.Element {
  return (
    <svg
      width='8'
      height='13'
      viewBox='0 0 16 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ marginBottom: -1 }}
    >
      <path d='M2 2L12 13L2 24' stroke='#727272' strokeWidth='4' />
    </svg>
  );
}

function ChildBreadcrumbsIndicator(): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='16'
      viewBox='0 0 17 24'
      fill='#808080'
      style={{ marginLeft: 8, marginBottom: -2 }}
    >
      <path d='M8.5 0L0 9H17L8.5 0Z' />
      <path d='M8.5 24L17 15L7.86805e-07 15L8.5 24Z' />
    </svg>
  );
}

function OpenMenuIcon(): JSX.Element {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 7H30'
        stroke='#B2B2B2'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M4 16H30'
        stroke='#B2B2B2'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M4 25H30'
        stroke='#B2B2B2'
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  );
}

function CloseMenuIcon(): JSX.Element {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 6L26 26'
        stroke='#DEDEDE'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M26 6L6 26'
        stroke='#DEDEDE'
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  );
}

function DescendingArrow(): JSX.Element {
  return (
    <svg
      width='16'
      height='27'
      viewBox='0 0 29 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ marginTop: -5, marginBottom: -5, marginRight: 8 }}
    >
      <path d='M21.0218 13L26.0218 18L21.0218 23V18V13Z' fill='#DEDEDE' />
      <path
        d='M2.02178 0V12C1.81776 16.5389 2.98857 17.9758 8.02178 18H26.0218M26.0218 18L21.0218 13V23L26.0218 18Z'
        stroke='#DEDEDE'
        strokeWidth='3'
      />
    </svg>
  );
}

export function getGameLabel(header: ArchonHeaderViewModel): string {
  return renderEnhancedMarkdownToString(
    header.defaultBreadcrumbs[1]?.label ?? '',
    null
  ).trim();
}
