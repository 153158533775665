import { SetStateAction, useMemo, useState } from 'react';
import { throttle } from 'lodash';

function useThrottledState<T>(
  initialState: T | (() => T),
  wait = 0,
  options?: {
    leading?: boolean | undefined;
    trailing?: boolean | undefined;
  }
): [T, (newState: SetStateAction<T>) => void] {
  const [state, setState] = useState(initialState);

  const debouncedSetState = useMemo(
    () =>
      throttle((newState) => setState(newState), wait, {
        leading: options?.leading ?? true,
        trailing: options?.trailing,
      }),
    [wait, options?.leading, options?.trailing]
  );

  return [state, debouncedSetState];
}

export default useThrottledState;
