import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore tsc complains about this import for some reason
import styles from './BreadcrumbSpecsMenu.module.scss';
import EnhancedMarkdown from '@resources/js/components/EnhancedMarkdown/EnhancedMarkdown';
import Link from 'next/link';
import { IconDefaultsContextProvider } from '@resources/js/contexts/useIconDefaults';
import { EnhancedMarkdownOptionsContextProvider } from '@resources/js/contexts/useEnhancedMarkdownOptions';

export default function BreadcrumbSpecsMenu({
  classes,
}: {
  classes: Array<{
    label: string;
    specs: Array<{
      label: string;
      url: string;
    }>;
  }>;
}): JSX.Element {
  return (
    <IconDefaultsContextProvider overrides={{ actor: { bordered: true } }}>
      <EnhancedMarkdownOptionsContextProvider value={{ lazyLoad: false }}>
        <div className={styles.grid}>
          {classes.map(({ label, specs }) => (
            <div key={label} className={styles.class}>
              <span className={styles.classLabel}>
                <EnhancedMarkdown markdown={label} wrapped={false} />
              </span>
              <div className={styles.specList}>
                {specs.map(({ label, url }) => (
                  <Link
                    key={label}
                    href={url}
                    prefetch={false}
                    className={styles.spec}
                  >
                    <EnhancedMarkdown markdown={label} wrapped={false} />
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </EnhancedMarkdownOptionsContextProvider>
    </IconDefaultsContextProvider>
  );
}
