function convertImageUrlToOptimizedImageUrl(
  url,
  width,
  quality,
  asRelativePath = true
) {
  const params = new URLSearchParams({
    url,
    w: width,
    q: quality,
  });

  return `${
    asRelativePath ? '' : 'https://www.archon.gg'
  }/_next/image?${params.toString()}`;
}

export default convertImageUrlToOptimizedImageUrl;
