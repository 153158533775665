import React, { useState, useContext, ReactNode } from 'react';
import PropTypes from 'prop-types';

export type ViewModels = Record<string, unknown>;

type ViewModelsContext = {
  viewModels: ViewModels;
  setViewModels: (viewModels: ViewModels) => void;
};

const ViewModelsContext = React.createContext<ViewModelsContext>({
  viewModels: {},
  setViewModels: () => null,
});

const ViewModelsProvider = ({
  children,
  value = {},
}: {
  children: ReactNode;
  value: ViewModels;
}): JSX.Element => {
  const [state, setState] = useState<ViewModels>(value);

  const context = {
    viewModels: state,
    setViewModels: setState,
  };

  return (
    <ViewModelsContext.Provider value={context}>
      {children}
    </ViewModelsContext.Provider>
  );
};

ViewModelsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object,
};

const useViewModels: () => ViewModelsContext = () => {
  return useContext(ViewModelsContext);
};

export default useViewModels;

export { ViewModelsProvider };
