import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore tsc seems to bug out and misreport this line
import styles from './ArchonTitle.module.scss';
import cx from 'classnames';
import Link from 'next/link';
import BadgeLabel from '@resources/js/components/BadgeLabel/BadgeLabel';
import Tooltip from '@resources/js/components/Tooltip/Tooltip';

export type ArchonTitleProps = {
  heading?: string;
  badgeLabel?: string;
  badgeTooltip?: string;
  fontSize?: number;
  includeMargin?: boolean;
};

export default function ArchonTitle({
  heading,
  badgeLabel,
  badgeTooltip,
  fontSize = 62,
  includeMargin = true,
}: ArchonTitleProps): JSX.Element {
  const badgeLabelComponent = (
    <BadgeLabel style={'orange'}>{badgeLabel}</BadgeLabel>
  );

  return (
    <div
      className={cx(styles.container, {
        [styles.includeMargin]: includeMargin,
      })}
    >
      <div className={styles.title} style={{ fontSize }}>
        <Link href={'/'}>Archon</Link>
      </div>
      {heading ? <h1 className={styles.heading}>{heading}</h1> : null}
      {badgeLabel && (
        <div className={styles.badge}>
          {badgeTooltip ? (
            <Tooltip
              content={
                <div className={styles.badgeTooltip}>{badgeTooltip}</div>
              }
              zIndex={2}
            >
              {badgeLabelComponent}
            </Tooltip>
          ) : (
            badgeLabelComponent
          )}
        </div>
      )}
    </div>
  );
}
