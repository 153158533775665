import React from 'react';
import Button from '@resources/js/components/Button/Button';

export type ModalProps = {
  content: React.ReactNode;
  buttonLabel: string;
  buttonOnClick?: () => void;
};

export default function ArchonModal({
  content,
  buttonLabel,
  buttonOnClick,
}: ModalProps): JSX.Element {
  return (
    <div className={'archon-modal'}>
      <div className={'archon-modal__content'}>
        <div>{content}</div>
        <div>
          <Button autoFocus={true} onClick={buttonOnClick}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}
