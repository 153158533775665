import React from 'react';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import { ASSET_SERVER_BASE_URL } from '../../../resources/js/constants/assetServer';

type ArchonLogoProps = {
  size?: number;
};

export default function ArchonLogo({ size }: ArchonLogoProps): JSX.Element {
  // TODO: When a logo is finalized, make this show the logo img/svg
  return (
    <OptimizedImage
      src={ASSET_SERVER_BASE_URL + '/img/archon/logo.svg'}
      alt={'Archon'}
      width={size ?? 32}
      height={size ?? 32}
      includeMargin={false}
      isZoomable={false}
      priority={true}
    />
  );
}
