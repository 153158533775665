import React from 'react';
import fromNow from '../../helpers/fromNow';

export type TinyArticlePreviewProps = {
  href?: string;
  target?: string;
  rel?: string;
  title: string;
  timestamp: number;
  thumbnailUrl: string;
};

function TinyArticlePreview({
  href,
  target = '_blank',
  rel = 'noreferrer',
  title,
  timestamp,
  thumbnailUrl,
}: TinyArticlePreviewProps): JSX.Element {
  const children = (
    <>
      <img
        src={thumbnailUrl}
        className={'tiny-article-preview__image'}
        alt={''}
      />
      <div className={'tiny-article-preview__texts'}>
        <div className={'tiny-article-preview__texts-title'}>{title}</div>
        <div className={'tiny-article-preview__texts-date'}>
          {fromNow(new Date(timestamp))}
        </div>
      </div>
    </>
  );

  if (href) {
    return (
      <a
        className={'tiny-article-preview__wrapper'}
        href={href}
        target={target}
        rel={rel}
      >
        {children}
      </a>
    );
  }

  return <span className={'tiny-article-preview__wrapper'}>{children}</span>;
}

export default TinyArticlePreview;
