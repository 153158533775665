import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import styles from './PlaywireAd.module.scss';
import cx from 'classnames';
import { AdPlacementPlaywireViewModel } from '@resources/js/typings/viewModels/adPlacements';
import useThrottledState from '@resources/js/hooks/useThrottledState';
import usePlaywire from '@resources/js/contexts/usePlaywire';

export type Props = AdPlacementPlaywireViewModel & {
  className?: string;
  fallback?: JSX.Element | null;
};

function PlaywireAd({
  id,
  adPlacementPlaywireType,
  minimumRefreshThresholdSeconds = 0,
  fallback = null,
}: Props): JSX.Element | null {
  const router = useRouter();
  const { isAvailable, isLoading, destroy, display } = usePlaywire();
  const [refreshCounter, setRefreshCounter] = useThrottledState(
    0,
    minimumRefreshThresholdSeconds * 1000
  );

  // Disabled to start, will be shown on first load, and routeChangeComplete event
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsHidden(containerRef.current?.offsetParent === null);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef]);

  useEffect(() => {
    setIsDisabled(false);
    setIsHidden(containerRef.current?.offsetParent === null);
  }, []);

  useEffect(() => {
    const onRouteChangeComplete = () => {
      setIsDisabled(false);
      setIsHidden(containerRef.current?.offsetParent === null);
      setRefreshCounter((generation) => generation + 1);
    };
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events, setRefreshCounter]);

  useEffect(() => {
    if (!isAvailable || isDisabled || isHidden) return;

    // Ignore selectorIds for these special ad units
    const selectorId = [
      'bottom_rail',
      'trendi_video',
      'corner_ad_video',
    ].includes(adPlacementPlaywireType)
      ? undefined
      : id;

    display({ selectorId, type: adPlacementPlaywireType }).catch(console.error);

    return () => {
      destroy({ selectorId, type: adPlacementPlaywireType }).catch(
        console.error
      );
    };
  }, [
    display,
    destroy,
    id,
    adPlacementPlaywireType,
    isAvailable,
    isHidden,
    refreshCounter,
    isDisabled,
  ]);

  return (
    <span
      className={cx([
        styles.container,
        styles[`type--${adPlacementPlaywireType}`],
      ])}
      ref={containerRef}
    >
      <div id={id} data-pw-desk={adPlacementPlaywireType}></div>
      {!isLoading && !isAvailable && fallback}
    </span>
  );
}

export default PlaywireAd;
