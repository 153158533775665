import { renderToStaticMarkup } from 'react-dom/server';
import { GameContextProvider } from '../../../contexts/useGame';
import EnhancedMarkdown from '../EnhancedMarkdown';
import React from 'react';

function mapHexCharEntities(input) {
  // note: this only handles hex char codes, not decimal or other HTML
  // entities
  return input
    .replace(/&#x([a-fA-F0-9]+);/g, (_match, code) =>
      String.fromCharCode(parseInt(code, 16))
    )
    .replace(/&amp;/g, '&')
    .replace(/&zwj;/g, '')
    .trim();
}

function renderEnhancedMarkdownToString(markdown, game) {
  const markup = renderToStaticMarkup(
    <GameContextProvider game={game}>
      <EnhancedMarkdown
        markdown={markdown}
        overrides={{
          p: { component: ({ children }) => <>{children}</> },
          span: { component: ({ children }) => <>{children}</> },
          Styled: { component: ({ children }) => <>{children}</> },
          ImageIcon: { component: ({ children }) => <>{children}</> },
        }}
        wrapped={false}
      />
    </GameContextProvider>
  );

  return mapHexCharEntities(markup);
}

export default renderEnhancedMarkdownToString;
